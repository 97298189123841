
import abi from "./contract/GlobalPool.json";

import Buy from "./component/buy";
import Buy1 from "./component/buy1";
import Buy2 from "./component/buy2";
import Buy3 from "./component/buy3";
import Buy4 from "./component/buy4";
import Buy5 from "./component/buy5";
import Buy6 from "./component/buy6";
import Buy7 from "./component/buy7";
import Buy8 from "./component/buy8";
import Buy9 from "./component/buy9";


import "./logo.png";


import "./copy.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[auto,setAuto]=useState("None");
  const[auto1,setAuto1]=useState("None");
  const[auto2,setAuto2]=useState("None");
  const[auto3,setAuto3]=useState("None");
  const[auto4,setAuto4]=useState("None");
  const[auto5,setAuto5]=useState("None");
  const[auto6,setAuto6]=useState("None");
  const[auto7,setAuto7]=useState("None");
  const[auto8,setAuto8]=useState("None");
  const[upd,setupd]=useState("None");
  
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [total,setTotal]=useState("None");
const [req,setreq]=useState("None");
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[wid,setwid]=useState("None");
const[b1,setb1]=useState("None");
const[b2,setb2]=useState("None");
const[b3,setb3]=useState("None");
const[b4,setb4]=useState("None");
const[b5,setb5]=useState("None");
const[b6,setb6]=useState("None");
const[b7,setb7]=useState("None");
const[b8,setb8]=useState("None");
const[b9,setb9]=useState("None");

const[b11,setb11]=useState("None");
const[b12,setb12]=useState("None");
const[b13,setb13]=useState("None");
const[b14,setb14]=useState("None");
const[b15,setb15]=useState("None");
const[b16,setb16]=useState("None");
const[b17,setb17]=useState("None");
const[b18,setb18]=useState("None");
const[b19,setb19]=useState("None");
const[reward,setreward]=useState("None");

  useEffect(() => {
    const connectWallet = async () => {
      const contractAddress = "0xcee7c585017a8579275a88fe48199867264b517a";
      const contractABI = abi.abi;
      const searchParams = new URLSearchParams(window.location.search);
      const myParam = searchParams.get('ref');
      console.log(myParam);
      
      
      document.querySelector("#name").value=myParam;
  if(myParam == null)
  {
    document.querySelector("#name").value="0xA430903afFA0D5C6DA6d37d156f048fA384798FA";
  }
      try {
        const { ethereum } = window;
        document.querySelector("#test").value = "Activate";
        if (ethereum) {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
           
          });
         
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            
            window.location.reload();
          });
          const provider = new ethers.providers.Web3Provider(ethereum);
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer 
          );
          setAccount(account);
          setState({ provider, signer, contract });

          
          document.querySelector("#us").value  = account[0];
          let alltasks = await contract.getMemos(account[0]);
          setTasks(alltasks);
          const b =Number(alltasks[2]);
          const refer = (alltasks[1]);
         setrefer(refer);
         document.querySelector("#hid").value  = (alltasks[1]);
         const direct =Number(alltasks[2]);
         setDirect(direct);
         const directs =  Number(alltasks[16]);
         setDirects(directs);

         const auto = Number(alltasks[3]) * 1.8;
         setAuto(auto);
         const auto1 = Number(alltasks[8]) * 3;
         setAuto1(auto1);
         const auto2 = Number(alltasks[9]) * 6;
         setAuto2(auto2);
         
         const auto3 = Number(alltasks[10]) * 12;
         setAuto3(auto3);
         
         const auto4 = Number(alltasks[11]) * 30;
         setAuto4(auto4);
         const auto5 = Number(alltasks[12]) * 60;
         setAuto5(auto5);
         const auto6 = Number(alltasks[13]) * 120;
         setAuto6(auto6);
         const auto7 = Number(alltasks[14]) * 300;
         setAuto7(auto7);
         const auto8 = Number(alltasks[15]) * 600;
         setAuto8(auto8);
         
         const b1 = Number(alltasks[17]) ;
         setb1(b1);
         const b2 = Number(alltasks[18]) ;
         setb2(b2);
         const b3 = Number(alltasks[19]) ;
         setb3(b3);
         const b4 = Number(alltasks[20]) ;
         setb4(b4);
         const b5 = Number(alltasks[21]) ;
         setb5(b5);
         const b6 = Number(alltasks[22]) ;
         setb6(b6);
         const b7 = Number(alltasks[23]) ;
         setb7(b7);
         const b8 = Number(alltasks[24]) ;
         setb8(b8);
         const b9 = Number(alltasks[25]) ;
         setb9(b9);

         const b11 = Number(alltasks[3]) ;
         setb11(b11);
         const b12 = Number(alltasks[8]) ;
         setb12(b12);
         const b13 = Number(alltasks[9]) ;
         setb13(b13);
         const b14 = Number(alltasks[10]) ;
         setb14(b14);
         const b15 = Number(alltasks[11]) ;
         setb15(b15);
         const b16 = Number(alltasks[12]) ;
         setb16(b16);
         const b17 = Number(alltasks[13]) ;
         setb17(b17);
         const b18 = Number(alltasks[14]) ;
         setb18(b18);
         const b19 = Number(alltasks[15]) ;
         setb19(b19);

         const reward = Number(alltasks[26]) ;
         setreward(reward);
         const upd = Number(alltasks[7]) ;
         setupd(upd);

         
         const pack = Number(alltasks[4]);
         document.querySelector("#pp").value  = Number(alltasks[4]);
         
    
    setpackage(pack);
    const dd = new Date(alltasks[8].timestamp * 1000).toLocaleString();
    setDD(dd);
         setB(b);
         const referlink = 'https://superautopool.com/?ref='+ account;
         setreflink(referlink);
         
         const tt = directs  +  auto  + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7 + auto8 + reward + upd ;
         settt(tt);


        
         document.querySelector("#link").value =referlink;
         const wid =(((Number(alltasks[0])) + 1) + 9200 ) * 41 ;
          setwid(wid);
          if(b1 > 29)
          {
           if(b2==0)
           {
             document.querySelector("#test3").value = "50";
           }
          }

          if(b2 > 29)
          {
           if(b3==0)
           {
             document.querySelector("#test4").value = "100";
           }
          }

          if(b3 > 29)
          {
           if(b4==0)
           {
             document.querySelector("#test5").value = "200";
           }
          }
          if(b4 > 29)
          {
           if(b5==0)
           {
             document.querySelector("#test6").value = "500";
           }
          }
          if(b5 > 29)
          {
           if(b6==0)
           {
             document.querySelector("#test7").value = "1000";
           }
          }

          if(b6 > 29)
          {
           if(b7==0)
           {
             document.querySelector("#test8").value = "2000";
           }
          }

          if(b7 > 29)
          {
           if(b8==0)
           {
             document.querySelector("#test9").value = "5000";
           }
          }

          if(b8 > 29)
          {
           if(b9==0)
           {
             document.querySelector("#test10").value = "10000";
           }
          }

          if(b11>0) 
          {
          if(b11%62==0)
          {
           document.querySelector("#test2").value = "30";
          }
        }
        if(b12>0) 
        {
          if(b12%62==0)
          {
           document.querySelector("#test3").value = "50";
          }
        }
        if(b13>0) 
        {
          if(b13%62==0)
          {
           document.querySelector("#test4").value = "100";
          }
        }
        if(b14>0) 
        {
          if(b14%62==0)
          {
           document.querySelector("#test5").value = "200";
          }
        }
        if(b15>0) 
        {
          if(b15%62==0)
          {
           document.querySelector("#test6").value = "500";
          }
        }
        if(b16>0) 
        {
          if(b16%62==0)
          {
           document.querySelector("#test7").value = "1000";
          }
        }
        if(b17>0) 
        {
          if(b17%62==0)
          {
           document.querySelector("#test8").value = "2000";
          }
        }
        if(b18>0) 
        {
          if(b18%62==0)
          {
           document.querySelector("#test9").value = "5000";
          }
        }
        if(b19>0) 
        {
          if(b19%62==0)
          {
           document.querySelector("#test10").value = "10000";
          }
        }

    console.log(pack);
    
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
  return ( 
     
<div style={{backgroundColor:"black"}}>
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="name"></input>
    <input type="hidden"   id ="test"></input>
    <input  type="hidden"  id ="test1"></input>
    <input type="hidden"   id ="test2"></input>
    <input   type="hidden" id ="test3"></input>
    <input type="hidden"   id ="test4"></input>
    <input  type="hidden"  id ="test5"></input>
    <input  type="hidden"  id ="test6"></input>
    <input  type="hidden"  id ="test7"></input>
    <input  type="hidden"  id ="test8"></input>
    <input  type="hidden"  id ="test9"></input>
    <input  type="hidden"  id ="test10"></input>


    <div class="page-container" >
 
    <table style={{color: "white",textAlign :"center",verticalAlign:"center", width :"100%",  backgroundColor:"white ",
}}>
            
      <tr><td>  
              
               <table width="100%">
               <tr>
               <td style={{  verticalAlign:"center", width :"100%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <img src ="sc.png" width="300px" height="200px" />
  </td></tr>
  <tr><td><br></br></td></tr>
     <tr>
               <td style={{  verticalAlign:"center", width :"100%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}> 
<br></br>
 <marquee>**Dear Leaders, A Unique Working Platform Superautopool.com has been launched for Unlimited Earning. So Please focus on your work and add more and more Direct members from your Refral link and get 60% Direct Refral income for Unlimited times. Every Users will be get Faster Autopools from here because Matrix is very small and only 62 Id's fill up all 5 level Autopools in all Upgraded Packages as per Seniority. World's First Decentralized Gaming Platform "Supergame" Launching Soon. This Ultimate Platform will be never end.**</marquee>
  <br></br></td></tr>        
  
         </table>      
           
             
         <div class="clearfix"> </div>	
         <table width="100%"  >

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <h6>REFER LINK - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></h6>
  <br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h6>MY SPONSOR - {refer}</h6>
  <br></br>
  <td style={{width:"3%" ,}}></td>
  </td>
  

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br>
  <Buy state={state} /> 
<br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
</table>


          <table width="100%">
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>TOTAL PACKAGE PURCHASED</h5>
<h6>  USDT {pack}  </h6><br></br> </td> 

<td style={{width:"3%" ,}}></td>
</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>MY DIRECT TEAM</h5>
<h6>   {direct}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>MY DIRECT INCOME</h5>
<h6>   {directs}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>SUPERPOOL UPGRADE INCOME</h5>
<h6>   {upd}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY TOTAL INCOME</h5>
<h6>  USDT {tt}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY TOTAL REWARD INCOME</h5>
<h6>  USDT {reward}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - I BONUS</h5>
<h6>  USDT {auto}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - II BONUS</h5>
<h6>  USDT {auto1}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - III BONUS</h5>
<h6>  USDT {auto2}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - IV BONUS</h5>
<h6>  USDT {auto3}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - V BONUS</h5>
<h6>  USDT {auto4}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - VI BONUS</h5>
<h6>  USDT {auto5}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - VII BONUS</h5>
<h6>  USDT {auto6}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - VIII BONUS</h5>
<h6>  USDT {auto7}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>SUPER AUTO POOL - IX BONUS</h5>
<h6>  USDT {auto8}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy1 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy2 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy3 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy4 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy5 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy6 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy7 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy8 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <br></br><br></br>
  <Buy9 state={state} /> 
<br></br><br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
</table>

           
                          
    
    <div class="clearfix"></div>
  
  <table style={{width:"100%" ,}}><tr><td  style={{color: "#191970" , textAlign :"center",verticalAlign:"center", }}>
	 © 2024 SUPERAUTOPOOL.COM . All Rights Reserved  </td></tr></table>
   </td></tr></table>

</div>
</div>


       

           
                   
	




     

  );
}

export default App;